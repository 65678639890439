export const ITEM_TYPES = {
  AGE_GROUP: 'AGE_GROUP',
  TASK_GROUP: 'TASK_GROUP',
  TASK: 'TASK',
}

export const COMPLETION_STATUS = {
  COMPLETED: 'COMPLETED',
  COMPLETION_REQUESTED: 'COMPLETION_REQUESTED',
  ARCHIVED: 'ARCHIVED',
  ACTIVE: 'ACTIVE',
  UNSTARTED: 'UNSTARTED',
}

export const TASK_GROUP_STATUS = {
  COMPLETED: 'COMPLETED',
}

export const AGE_GROUPS = {
  4: '053fa231362e95cb211c5eb85c3cbedb',
  5: 'b0bc122b1995418b828fa558c7d33414',
  6: 'fd0083b9a325c06430ba29cc6c6d1bac',
  7: '3ef690e4499894ed34577c83fdae7786',
  8: '0fe4dd441b0708f6bbff580d62392080',
}
