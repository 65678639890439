export const SET_ITEMS_BY_GUID = 'SET_ITEMS_BY_GUID'
export const SET_AGE_GROUPS = 'SET_AGE_GROUPS'
export const SET_SELECTED_AGE_GROUP = 'SET_SELECTED_AGE_GROUP'
export const SET_ACTIVITY_GROUPS = 'SET_ACTIVITY_GROUPS'
export const SET_SELECTED_ACTIVITY_GROUP = 'SET_SELECTED_ACTIVITY_GROUP'
export const SET_TRANSLATIONS = 'SET_TRANSLATIONS'
export const SET_SELECTED_LANGUAGE = 'SET_SELECTED_LANGUAGE'

// User
export const SET_USER = 'SET_USER'
export const SET_USER_GROUPS = 'SET_USER_GROUPS'
export const SET_GROUP_MEMBER_TASK = 'SET_GROUP_MEMBER_TASK'
export const SET_GROUP_MEMBER_TASKGROUP = 'SET_GROUP_MEMBER_TASKGROUP'
export const SET_GROUP_MEMBER_AGEGROUP = 'SET_GROUP_MEMBER_AGEGROUP'
export const DELETE_GROUP_MEMBER_TASK = 'DELETE_GROUP_MEMBER_TASK'
export const DELETE_GROUP_MEMBER_TASKGROUP = 'DELETE_GROUP_MEMBER_TASKGROUP'
export const DELETE_GROUP_MEMBER_AGEGROUP = 'DELETE_GROUP_MEMBER_AGEGROUP'

// Tasks
export const SET_TASKS = 'SET_TASKS'
export const DELETE_ACTIVE = 'DELETE_ACTIVE'
export const SET_MANDATORY_TASKS = 'SET_MANDATORY_TASKS'
export const SET_TASKGROUP_REQUIREMENTS = 'SET_TASKGROUP_REQUIREMENTS'

// User Activitygroups
export const SET_USER_ACTIVITY_GROUPS = 'SET_USER_ACTIVITY_GROUPS'

// User completed agegroups
export const SET_USER_AGE_GROUPS = 'SET_USER_AGE_GROUPS'

// Favourites
export const SET_FAVOURITES = 'SET_FAVOURITES'
export const DELETE_FAVOURITE = 'DELETE_FAVOURITE'
export const ADD_FAVOURITE = 'ADD_FAVOURITE'

// Notifications
export const SET_NOTIFICATION = 'SET_NOTIFICATION'
export const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS'
export const MARK_NOTIFICATION_READ = 'MARK_NOTIFICATION_READ'
export const TOGGLE_SHOW_NOTIFICATIONS = 'TOGGLE_SHOW_NOTIFICATIONS'
export const MARK_ALL_NOTIFICATIONS_READ = 'MARK_ALL_NOTIFICATIONS_READ'
